.media-page{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.media-page-list{
  width: 100%;
  max-width: 414px;
  height: 100%;
  flex-shrink: 0;
}

.media-page-pannel{
  height: 100%;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  background-color: var(--clr-base);
  color:var(--clr-font);
}

@media screen and (max-width: 480px) {
  .media-page-pannel {
    position: fixed;
    right: -100%;
    transition: right 0.3s ease-out;
  }

  .media-page-pannel.active{
    right: 0;
  }
}




.pannel-body{
  height: 100%;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--clr-primary);
  box-sizing: border-box;
}

.pannel-title{
  width: 100%;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.pannel-media{
  width: 100%;
}

.pannel-content{
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-y: auto;
}
.pannel-preview{
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
}
.pannel-buttons{
  margin-top: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.pannel-buttons button{ 
  margin: 0;
  padding: 0;
  border: 1px solid black;
  color: black;
  outline: none;
  width: 100%;
  height: 36.5px;
}