.youtube-page{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.youtube-page-list{
  width: 100%;
  max-width: 414px;
  height: 100%;
  flex-shrink: 0;
}

.youtube-page-pannel{
  height: 100%;
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  background-color: var(--clr-base);
  color:var(--clr-font);
}

@media screen and (max-width: 480px) {
  .youtube-page-pannel {
    position: fixed;
    right: -100%;
    transition: right 0.3s ease-out;
  }

  .youtube-page-pannel.active{
    right: 0;
  }
}