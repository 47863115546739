.pannel-previews-contianer{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr)); /* 重复自动填充列，每列最小宽度为190px，平均占据可用空间 */
  gap: 5px; /* 列之间的间距（根据需要调整） */
  grid-row-gap: 5px;
}

.pannel-previews-item{
  min-width: 170px; /* 设置每个标签项的最小宽度 */
  box-sizing: border-box; /* 包含内边距和边框在元素的总宽度和高度中 */
}