.mpannel-body{
  /* height: 100%; */
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--clr-primary);
  box-sizing: border-box;
}

.mpannel-media{
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: black;
}

.mpannel-buttons{
  margin-top: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-shrink: 0;
}

.mpannel-buttons button{ 
  margin: 0;
  padding: 0;
  border: 1px solid black;
  color: black;
  outline: none;
  width: 100%;
  height: 36.5px;
}