
.navbar-side{
  height: 100%;
  width: 265px;
  background: var(--clr-base);
  transition: all 0.5s ease;
  user-select: none;
  border-right: 1px solid var(--clr-primary);
  box-sizing: border-box;
  flex-shrink: 0;
}
.navbar-side.close{
  width: 78px;
}
.navbar-side ul{
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
  overflow: auto;
}
.navbar-side-item{
  color: var(--clr-font);
  height: 48px;
  width: 100%;
  cursor: pointer;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-side-item:hover{
  background: var(--clr-primary-lighter);
}
.navbar-side-item.choice{
  background: var(--clr-primary-lighter);
}

.navbar-side-link{
  text-decoration: none;
  color: var(--clr-font);
  display:flex;
  align-items: center;
}

.navbar-side span {
  width: 150px;
}
.navbar-side.close span{
  display: none;
  pointer-events: none;
}

@media screen and (max-width: 480px) {
  .navbar-side {
      display: none;
  }
}