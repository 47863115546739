.trans-line{
  line-height: 14px;
}

.trans-text{
  font-size: 14px;
}

.trans-time{
  /* font-family: 'Roboto Mono', monospace; */
  font-family: 'Fira Mono', monospace;
}

.highlight {
  font-weight: bold;
}