:root {
  --clr-font: #000000;
  --clr-base: white;
  --clr-primary:#000000;
  --clr-primary-lighter:#666666;

}

html,body{
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.main-container{
  height: 100%;
  display: flex;
  flex-direction: row;
}