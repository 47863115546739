.mvideo-player-container{
  display:flex;
  justify-content: center;
  height: 100%;
  /* background-color: gray; */
  /* position: relative; */
}

.mvideo-player {
  height: 100%;
  width: 100%;
  /* transform: rotate(90deg); */
}



@media screen and (max-width: 480px) {
  .mvideo-player {
    width: 100%;
  }

}